<template>
  <div>
    <div class="mb-2">
      <div
        v-if="currentForm && isFormSelectionLinkVisible"
        class="form-dropdown"
      >
        <multi-select
          name="name"
          placeholder="Type to search"
          track-by="id"
          label="name"
          class="custom-dropdown"
          :value="currentForm"
          :options="customFormOptions"
          :multiple="false"
          :searchable="false"
          :deselect-label="deselectLabel"
          data-tc-form-drop-down
          @input="changeSelectedForm"
        />

        <div
          v-if="isMultiWorkspace() && allowMultiCompany && currentForm"
          class="text-muted"
        >
          <span v-if="!!!selectedCompany">{{ currentForm.company.name }}</span>
          <span v-if="!!!selectedCompany && (currentForm.company.workspaceIds.length > 1)">/</span>
          <span v-if="currentForm.company.workspaceIds.length > 1">{{ currentForm.workspace.name }}</span>
        </div>
      </div>

      <div v-else-if="currentForm">
        <h5 class="text-secondary font-weight-normal">
          {{ currentForm.formName }}
        </h5>
      </div>


      <div
        v-if="isFormSelectionLinkVisible"
        class="dropdown-menu not-as-small row dissmissible-menu"
        :class="{ hidden: !showFormSelectDropdown }"
        data-tc-status-options
      >
        <a
          v-for="(formOption, index) in customFormOptions"
          :key="formOption.id"
          v-tooltip.left="{
            content: formOption.formName,
            show: (formOption && formOption.formName > 20 && hoveredIndex == index),
            trigger: 'manual',
          }"
          class="dropdown-item clickable readable-length--small"
          href="#"
          data-tc-status-option
          @mouseover="hoveredIndex = index"
          @mouseleave="hoveredIndex = null"
          @click.stop.prevent="selectForm(formOption)"
        >
          {{ formOption.name }}
        </a>
      </div>
    </div>
    <hr class="mt-0">
    <form
      v-if="!!currentForm && (!formSelection || !isMultiWorkspace() || !allowMultiCompany) && !openFormSubmitSuccess"
      id="custom-form"
      ref="form"
      accept-charset="UTF-8"
      enctype="multipart/form-data"
    >
      <div
        v-for="field in currentForm.formFields"
        :id="`field-${field.id}`"
        :key="`field-${field.id}`"
        :ref="`field_wrapper${field.id}`"
        class="scroll-elem"
      >
        <field-viewer-input
          v-if="canShowField(field)"
          :ref="`field${field.id}`"
          :field="field"
          :attachment-ids.sync="attachmentIds"
          :value="valueFromObject(field)"
          :set-default-key="setDefaultKey[field.id]"
          :company-id="currentForm.companyId"
          :show-people-list="showPeopleList"
          :mfa-phone-field="mfaWithNumberExist"
          class="form-group"
          :data-tc="field.name"
          :disabled="field.disabled"
          :required="field.required"
          @update-set-default-key="updateSaveDefaultKey"
          @input="saveValue"
          @delete="deleteValue"
          @attachment-uploading-status="uploadingStatus"
          @update="updateFormField"
          @open-modal="openMfaPhoneModal"
        />

        <checklist-select
          v-if="shouldShowChecklist(field.name)"
          ref="checklistSelect"
          :key="'checklist-field'"
          class="form-group"
        />
      </div>

      <div v-if="companyModule == 'helpdesk' && isWrite">
        <div class="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h6 class="text-left font-weight-normal mb-0 form-group">
              Mute Notifications
            </h6>
            <span class="text-muted sub-text small">On enabling this option, you won't recieve any notification(s) related to this ticket </span>
          </div>
          <material-toggle
            class="toggle-margin"
            :init-active="muteNotification"
            @toggle-sample="toggleMuteNotification"
          />
        </div>
      </div>

      <div v-if="showSecuritySection && companyModule == 'company_user'">
        <h5 class="mt-5 font-weight-normal">
          Security
        </h5>
        <hr class="mt-0">

        <div v-if="accessNotLimited">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h6 class="text-left font-weight-normal mb-0 form-group">
                Enable two factor authentication
              </h6>
              <span class="text-muted sub-text small">Protect your account with two factor authentication.</span>
            </div>
            <material-toggle
              class="toggle-margin"
              :init-active="isMfaEnabled()"
              @toggle-sample="toggleCompanyUserMfa"
            />
          </div>
        </div>

        <div
          v-if="userLocked"
          class="form-group"
        >
          <h6 class="text-left font-weight-normal mb-0 form-group">
            Teammate status
          </h6>
          <span class="text-muted sub-text small">Locked</span>
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="mt-2">
              <span class="text-danger small mb-2">
                Due to several retries, the user is locked.
              </span>
              <div class="d-flex align-items-center">
                <input
                  v-model="userUnlockedValue"
                  class="mr-2 clickable"
                  type="checkbox"
                  @change="userLockedStatus"
                >
                <span class="small mt-1">
                  Unlock user to log in via two factor authentication.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="text-center border-top"
        :class="{
          'stick-btn-holder--open-ticket mt-5': isTicketPortal && !modernDesignEnabled,
          'sticky-btn-holder bg-lighter  border-light' : !modernDesignEnabled
        }"
      >
        <div class="mt-5 d-flex justify-content-center">
          <vue-recaptcha
            v-if="isTicketPortal && !isSubmitting && !isUploading && isCaptchaEnabled"
            ref="recaptcha"
            size="invisible"
            :sitekey="recaptchaSiteKey"
            :load-recaptcha-script="true"
            @verify="verifyCaptcha"
            @expired="captchaExpired"
            @error="captchaError"
          >
            <button
              class="btn btn-primary"
              type="submit"
              :disabled="isSubmitting || isUploading"
              :data-tc-create="readableModuleType"
            >
              <span :class="{ 'mr-4': isSubmitting || isUploading }">
                {{ buttonSubject }} {{ readableModuleType }}
              </span>
            </button>
          </vue-recaptcha>
          <div v-else>
            <submit-button
              :is-saving="isSubmitting || isUploading"
              :btn-content="`${buttonSubject} ${readableModuleType}`"
              :saving-content="`${buttonSubject} ${readableModuleType}`"
              :btn-classes="'px-4 form-create-btn'"
              @submit="saveObject"
            />
          </div>
        </div>
      </div>
    </form>
    <form
      v-else-if="formSelection && isMultiWorkspace() && allowMultiCompany"
      id="change-form"
      ref="formSelection"
      accept-charset="UTF-8"
      enctype="multipart/form-data"
    >
      <h5>Select a New Ticket Form</h5>
      <div
        v-if="customFormOptions"
        class="form-group control"
      >
        <label>Ticket Type</label>
        <custom-form-select
          v-model="selectedForm"
          class="w-100"
          @input="fetchCustomForm"
        />
      </div>
      <div class="form-group">
        <submit-button
          btn-content="Save"
          saving-content="Saving"
          @submit="fetchCustomForm"
        />
        <button
          class="btn-outline btn float-left"
          data-tc-cancel
          @click.prevent.stop="cancelFormSelection"
        >
          Cancel
        </button>
      </div>
    </form>
    <div v-else-if="!openFormSubmitSuccess">
      <h5 class="text-secondary float-left">
        Loading
      </h5>
      <pulse-loader
        loading
        class="d-inline ml-2"
        :color="pulseLoaderColor"
        size="0.5rem"
      />
    </div>
    <div v-else-if="openFormSubmitSuccess">
      <div class="box box--flat flex-column align-items-start justify-content-center rounded bg-very-light border-success-light col-8 offset-2 mt-5 mb-3">
        <div class="d-flex align-items-center mb-0">
          <img
            src="~images/asset_manual_entry_icon.svg"
            class="d-inline-block mr-2 pl-0.5"
            alt="Workspaces Icon"
            height="40"
            width="40"
          >
          <h5
            class="mb-0 ml-1"
            data-tc-message1
          >
            Success!
          </h5>
        </div>
        <h6
          class="mb-4 font-weight-normal ml-5 pl-3"
          data-tc-message2
        >
          Your ticket has been submitted.
        </h6>

        <div class="d-flex w-100 justify-content-end mt-1">
          <button
            class="btn btn-outline-primary btm-sm"
            @click="startNewTicket"
          >
            Start a new ticket
          </button>
        </div>
      </div>
    </div>
    <staff-group-field
      v-if="companyModule === 'company_user' && !!currentForm"
      ref="staffGroupField"
    />
    <mfa-edit-phone-modal
      v-if="currentCompanyUser && buttonSubject == 'Update' && companyModule == 'company_user'"
      ref="phoneModal"
      :user-id="currentCompanyUser.id"
      @updated="updatedMfaPhone"
    />
  </div>
</template>

<script>
  import ChecklistSelect from 'components/checklist_select.vue';
  import MultiSelect from 'vue-multiselect';
  import http from 'common/http';
  import { mapMutations, mapGetters, mapActions } from 'vuex';
  import _cloneDeep from 'lodash/cloneDeep';
  import _get from 'lodash/get';
  import multiCompany from 'mixins/multi_company';
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
  import customFormHelper from 'mixins/custom_form_helper';
  import validation from 'mixins/custom_forms/validation';
  import VueRecaptcha from 'vue-recaptcha';
  import permissionsHelper from 'mixins/permissions_helper';
  import StaffGroupField from 'components/staff_group_field.vue';
  import MaterialToggle from "../material_toggle.vue";
  import MfaEditPhoneModal from '../../mfa/mfa_edit_phone_modal.vue';
  import CustomFormSelect from './custom_form_select.vue';
  import FieldViewerInput from './field_viewer_input.vue';
  import SubmitButton from '../submit_button.vue';

  export default {
    $_veeValidate: {
      validator: 'new',
    },
    components: {
      PulseLoader,
      CustomFormSelect,
      FieldViewerInput,
      MultiSelect,
      MaterialToggle,
      MfaEditPhoneModal,
      VueRecaptcha,
      SubmitButton,
      StaffGroupField,
      ChecklistSelect,
    },
    mixins: [multiCompany, customFormHelper, validation, permissionsHelper],
    props: {
      isTicketPortal: {
        type: Boolean,
        default: false,
      },
      modernDesignEnabled: {
        type: Boolean,
        default: false,
      },
      /* 
        So, if form is null, then we are handling the form selection.  Otherwise, 
        the parent will handle the form selection.
      */
      form: {
        type: Object,
        default: null,
      },
      companyModule: {
        type: String,
        default: "helpdesk",
      },
      editForm: {
        type: Boolean,
        default: false,
      },
      renderFromModal: {
        type: Boolean,
        required: false,
        default: false,
      },
      allowMultiCompany: {
        type: Boolean,
        default: true,
      },
      differentModule: {
        type: Boolean,
        default: false,
      },
      deselectLabel: {
        type: String,
        default: 'Press enter to remove',
      },
    },
    data() {
      return {
        attachmentsTotalSize: 0,
        isSubmitting: false,
        isUploading: false,
        attachmentIds: [],
        selectedCompany: null,
        selectedForm: this.form, // Either the form selected or hard coded via props (just id/name)
        currentForm: null, // Full form object retrieved from the server
        cloneCurrentForm: null,
        customFormOptions: null,
        formSelection: false,
        object: {
          values: [],
          deletedValues: [],
        },
        setDefaultKey: [],
        moduleUrls: {
          'helpdesk': 'custom_form_tickets',
          'location': 'custom_form_locations',
          'company_user': 'custom_form_users',
        },
        userFields: ['first_name', 'last_name', 'email'],
        workspaceId: null,
        hoveredIndex: null,
        showFormSelectDropdown: false,
        scrollHeight: null,
        openFormSubmitSuccess: false,
        muteNotification: false,
        isHelpCenterPortal: window.location.pathname.startsWith('/help_center'),
      };
    },
    computed: {
      ...mapGetters([
        'helpdeskEmailSetting',
        'helpdeskEmails',
        'helpCenterHyperlinksColor',
        'currentCompany',
      ]),
      ...mapGetters('GlobalStore', [
        'isRobot',
        'mfaSettings',
        'recaptchaSiteKey',
      ]),
      isCaptchaEnabled() {
        return this.$runCaptcha;
      },
      isFormSelectionLinkVisible() {
        if (this.editForm) {
          return false;
        } 
          const length = _get(this, 'customFormOptions.length');
          return ((this.isMultiWorkspace() && this.allowMultiCompany) || length > 1) && !this.isTicketPortal;
        
      },
      changeFormText() {
        return "form";
      },
      formId() {
        const id = _get(this.$route.query, "formId");
        if (id) {
          return id;
        }
        if (this.form) {
          return this.form.id;
        }
        return null;
      },
      buttonSubject() {
        return this.editForm ? 'Update' : 'Create';
      },
      moduleUrl() {
        return `/custom_forms/${this.currentForm.id}/${this.moduleUrls[this.companyModule]}/${this.$route.params.id}.json`;
      },
      readableModuleType() {
        if (this.getModuleData && this.getModuleData.companyModuleType) {
          if (this.getModuleData.companyModule === "company_user") {
            return "teammate";
          }
          return this.getModuleData.companyModuleType;
        }
        return null;
      },
      helpCenterPortal() {
        return this.$router.options.base === '/help_center';
      },
      pulseLoaderColor() {
        return this.modernDesignEnabled ? this.helpCenterHyperlinksColor : "#0d6efd";
      },
      showChecklist() {
        if (this.companyModule === 'helpdesk') {
          return this.currentForm.moduleForm.showChecklist;
        }
        return false;
      },
      showPeopleList() {
        if (this.companyModule === 'helpdesk') {
          return this.currentForm.moduleForm.showPeopleList;
        }
        return false;
      },
    },
    watch: {
      form(newValue, oldValue) {
        const newId = _get(newValue, 'id');
        const oldId = _get(oldValue, 'id');
        if (newId !== oldId) {
          this.selectedForm = this.form;
          this.fetchCustomForm();
        }
      },
    },
    updated() {
      if (this.currentForm && this.currentForm.formFields) {
        const required = this.currentForm.formFields.filter(field => field.required);

        const requiredMap = !this.renderFromModal ? required.map((r, idx) => 
          ({
            ...r,
            id: r.name,
            active: idx === 0,
            sectionRef: this.$refs[`field_wrapper${r.id}`][0],
          })
        ) : required;

        this.$parent.$emit("update:required-form-fields", requiredMap);
      }
    },
    mounted() {
      this.selectedCompany = getCompanyFromStorage();
      if (!this.selectedCompany) {
        this.fetchCustomFormsAndCompany();
      }
    },
    methods: {
      ...mapMutations(['setLoadingStatus', 'setAttachmentsSaved']),
      ...mapMutations('GlobalStore', ['setRobotStatus']),
      ...mapActions(['fetchCustomEmails', 'fetchCompanyUserOptions']),

      onWorkspaceChange() {
        this.fetchCustomFormsAndCompany();
        if (this.companyModule === 'helpdesk') {
          this.fetchCompanyUserOptions({ 
            archived_users_only: true,
            is_help_center_portal: this.isHelpCenterPortal, 
          });
        }
      },
      updateSaveDefaultKey(id) {
        this.setDefaultKey[id] = false;
      },
      updateCustomFormOptions() {
        this.selectedForm = null;
        this.clearCustomFormOptions();
        this.fetchCustomFormOptions();
      },
      showFormSelection() {
        this.selectedForm = {
          id: this.currentForm.id,
          name: this.currentForm.formName,
        };
        if (!this.customFormOptions) {
          this.fetchCustomFormOptions();
        }
        this.formSelection = true;
      },
      cancelFormSelection() {
        this.selectedForm = {
          id: this.currentForm.id,
          name: this.currentForm.formName,
        };
        this.hideFormSelection();
      },
      hideFormSelection() {
        // If we're given a form, then the form selection is handled by the parent
        if (this.form) {
          return;
        }
        this.formSelection = false;
      },
      clearCustomFormOptions() {
        this.customFormOptions = null;
      },
      fetchCustomForm() {
        const params = {
          company_module: this.companyModule,
          company_id: this.currentCompany,
        };
        if (this.editForm) {
          params.module_id = this.$route.params.id;
        }

        this.formSelection = false;
        const selectedFormId = _get(this, 'selectedForm.id');
        if (!selectedFormId) {
          return;
        }
        const formUrl = `/custom_forms/${selectedFormId}.json`;
        this.setLoadingStatus(true);
        http
          .get(formUrl, { params })
          .then(res => {
            this.currentForm = _cloneDeep(res.data);
            this.cloneCurrentForm = _cloneDeep(res.data);
            this.workspaceId = res.data.workspace.id;
            if (this.formSelection) {
              this.hideFormSelection();
            }
            this.setLoadingStatus(false);
          })
          .catch(() => {
            this.emitError('We encountered an error getting the selected form, please refresh the page and try again');
          });
      },
      fetchCustomFormOptions() {
        const params = {
          company_module: this.companyModule,
          portal: this.isTicketPortal,
          active: true,
          company_id: this.currentCompany,
        };
        const optionsUrl = `/custom_form_options.json`;
        return http
          .get(optionsUrl, { params })
          .then((res) => {
            this.customFormOptions = res.data;
            if (this.customFormOptions && this.formId) {
              this.selectedForm = this.customFormOptions.find((o) => o.id === this.formId);
              if (!this.selectedForm) {
                [this.selectedForm] = this.customFormOptions;
              }
            } else {
              this.selectedForm = this.customFormOptions.find(form => form.default);
              if (!this.selectedForm) {
                [this.selectedForm] = this.customFormOptions;
              }
            }
          })
          .catch(() => {
            this.emitError('Sorry, there was an error in loading company info. Please try again later.');
          });
      },
      canShowField(field) {
        if (this.isTicketPortal) {
          return !field.private;
        }
        return true;
      },
      valueFromObject(field) {
        return this.object.values.filter((v) => v.customFormFieldId === field.id );
      },
      saveValue(field, value) {
        this.object.customFormId = field.customFormId;
        this.saveEntityValue(field, value);
        this.object = _cloneDeep(this.object);
      },
      deleteValue(field, value) {
        this.deleteEntityValue(field, value);
      },
      validateObject() {
        const formInputIds = this.currentForm.formFields.map(field => field.id);
        const fields = {};
        this.zipCodeValidation();
        this.currentForm.formFields.forEach((f) => {
          fields[f.id] = f;
        });
        let field = null;
        for (let idx = 0; idx < formInputIds.length; idx += 1) {
          const refField = this.$refs[`field${formInputIds[idx]}`];
          field = fields[formInputIds[idx]];
          if (refField) {
            const errorValue = refField[0].showErrors();
            if (field.required && !errorValue) {
              this.$el.querySelector(`#field-${field.id}`).scrollIntoView({ block: "start", behavior: "smooth" });
            }
          }
        }
      },
      saveObject() {
        this.validateObject();

        if (this.isCompanyUserModule) {
          this.validateEmail().then(() => {
            this.processObject();
          });
        } else {
          this.processObject();
        }
      },
      processObject() {
        if (this.$refs.form && !this.$refs.form.checkValidity()) {
          this.emitError('Please correct the errors before saving.');
          return;
        } else if (this.editForm) {
          this.updateObject();
        } else {
          this.createObject();
        }
      },
      createObject() {
        const url = `/custom_forms/${this.currentForm.id}/${this.moduleUrls[this.companyModule]}.json`;

        if (this.isCompanyUserModule) {
          this.setUserData();
        }

        this.isSubmitting = true;

        http
          .post(url, { json: JSON.stringify(this.object), attachmentIds: this.attachmentIds } )
          .then(res => {
            if (this.$refs.staffGroupField) {
              this.$refs.staffGroupField.addToGroup(res.data.entity);
            }
            if (this.companyModule === 'helpdesk' && this.$refs.checklistSelect) {
              this.$refs.checklistSelect[0]?.addChecklistsToTicket(res.data.entity);
            }
            this.emitSuccess(`${this.readableModuleType} successfully added`);
            this.setAttachmentsSaved(true);
            if (this.isTicketPortal) {
              this.openFormSubmitSuccess = true;
            } else {
              this.$emit("create", res.data.entity);
            }            
          })
          .catch(err => {
            if (err.response.data.message) {
              this.emitError(`We encountered an error saving the form. ${err.response.data.message}`);
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });

      },
      updateObject() {
        this.object.values.map(value => {
          const customFormField = this.currentForm.formFields.find(formField => {
            if (_get(formField, 'customFormValue.customFormFieldId')) {
              return formField.customFormValue.customFormFieldId === value.customFormFieldId;
            }
            return false;
          });
          if (customFormField) {
            Object.assign(value, { customFormValueId: customFormField.customFormValue.id});
          }
          return null;
        });

        if (this.isCompanyUserModule) {
          this.setUserData();
        }

        const params = {
          json: JSON.stringify(this.object),
          attachmentIds: this.attachmentIds,
          company_module: this.companyModule,
        };

        this.isSubmitting = true;

        http
          .put(this.moduleUrl, { params })
          .then(res => {
            this.emitSuccess(`${this.readableModuleType} successfully updated`);
            this.$emit("update", res.data.entity.id);
          })
          .catch(err => {
            if (err.response.data.message) {
              this.emitError(`We encountered an error updating the form. ${err.response.data.message}`);
            }
          })
          .finally(() => {
            this.isSubmitting = false;
          });

      },
      uploadingStatus(status) {
        this.isUploading = status;
      },
      updateFormField(params) {
        this.updateField(params)
          .then((res) => {
            const field = res.data;
            const myForm = _cloneDeep(this.currentForm);
            const idx = myForm.formFields.findIndex((f) => f.id === field.id);
            if (idx > -1) {
              myForm.formFields[idx] = field;
            }
            this.currentForm = myForm;
          });
      },
      openMfaPhoneModal() {
        this.$refs.phoneModal.resetValues();
        this.$refs.phoneModal.openMfaPhoneModal();
      },
      verifyCaptcha(resp) {
        if (!this.isCaptchaEnabled) {
          this.saveObject();
          return;
        }
        this.isSubmitting = true;
        http
          .post('/verify_recaptcha.json', { response: resp })
          .then((res) => {
            if (res.data.success) {
              this.setRobotStatus(false);
              this.saveObject();
              this.isSubmitting = false;
            } else {
              this.isSubmitting = false;
              this.emitError("Sorry silly robot, this app is for humans! (If you're a human and believe you're seeing this in error, please contact our <a href='mailto:support@gogenuity.com' class='text-white text-underline'>support team</a>.");
              this.resetCaptcha();
            }
          })
          .catch(() => {
            this.isSubmitting = false;
            this.resetCaptcha();
          });
      },
      captchaExpired() {
        if (!this.isCaptchaEnabled) {
          return;
        }
        this.resetCaptcha();
        this.setRobotStatus(true);
      },
      captchaError() {
        if (!this.isCaptchaEnabled) {
          return;
        }
        this.emitError('Sorry, we are having some trouble verifying with reCAPTCHA. Please <a href="javascript:window.location.reload(true)" class="text-underline text-white">refresh</a> and try again, or reach out to our <a href="mailto:support@gogenuity.com" class="text-underline text-white">support team</a>.');
      },
      resetCaptcha() {
        if (!this.isCaptchaEnabled) {
          return;
        }
        this.$refs.recaptcha.reset();
      },
      validateEmail() {
        const emailField = this.currentForm.formFields.find(field => field.name === 'email');
        const refField = emailField ? this.$refs[`field${emailField.id}`] : null;
        this.setUserData();
        let url = `/validate_emails.json?email=${encodeURIComponent(this.object.user_data.email)}`;
        if (this.currentCompanyUser && this.currentCompanyUser.id) {
          url = `/validate_emails/${this.currentCompanyUser.id}.json?email=${encodeURIComponent(this.object.user_data.email)}`;
        }

        return http
          .get(url)
          .then(res => {
            if (!refField || refField.length === 0) {
              return;
            }
            const { data }  = res;
            if (data === 'taken') {
              refField[0].showEmailFieldError("Email must be unique");
            } else if (data === "invalid") {
              refField[0].showEmailFieldError("Looks like that email is invalid. Try using another one.");
            }
          });
      },
      changeSelectedForm(form) {
        let url = null;
        if (form) {
          const myForms = this.customFormOptions.filter(f => f.id === form.id);
          if (myForms && myForms.length > 0) {
            this.currentForm = myForms;
            if (this.differentModule) {
              return this.$emit('change', this.currentForm[0]);
            }
            if (this.companyModule === 'helpdesk') {
              url = `/new`;
            } else if (this.companyModule === 'company_user') {
              url = `/users/new`;
            } else if (this.companyModule === 'location') {
              url = `/locations/new`;
            }
            if (url) {
              this.$router.push(`${url}?formId=${this.currentForm[0].id}`);
            }
          }
        }
        return null;
      },
      startNewTicket() {
        window.location.reload();
      },
      toggleMuteNotification() {
       this.muteNotification = !this.muteNotification;
       this.object.muteNotification = this.muteNotification;
      },
      fetchCustomFormsAndCompany() {
        this.showFirstTimeHelpdesk('create');
        if (this.workspaceId != null && !this.isTicketPortal && this.workspaceId !== getWorkspaceFromStorage().id) {
          return this.$router.replace('/');
        }

        if (this.form) {
          this.selectedForm = this.form;
          this.fetchCustomForm();
          if (!this.isTicketPortal) {
            this.fetchCustomFormOptions();
          }
        } else if (!this.customFormOptions && !this.isTicketPortal) {
          this.fetchCustomFormOptions().then(() => {
            this.fetchCustomForm();
          });
        } else {
          this.fetchCustomForm();
        }
        if (this.buttonSubject === 'Update' && this.isCompanyUserModule && !this.renderFromModal) {
          this.loadCompanyUser();
        }
        if (!this.helpdeskEmails?.length) {
          this.fetchCustomEmails();
        }
        return null;
      },
      shouldShowChecklist(fieldName) {
        return fieldName === 'assigned_to' && !this.helpCenterPortal && this.showChecklist;
      },
    },
  };
</script>

<style lang="scss" scoped>
.dropdown-menu {
  display: inline-block;
  left: auto;
  right: auto;
  top: 20px;
}

.save-record-btn--fixed {
  .asset-spinner {
    left: -50px;
    position: absolute;
  }
}

.scroll-elem {
  scroll-margin-top: $header-height + 1rem;
}

.form-dropdown {
  .custom-dropdown {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    min-height: auto!important;

    /deep/ .multiselect__tags {
      border: 0;
      padding: 0;
      max-width: 150px;
      display: inline-flex;
      min-height: auto;

      .multiselect__single {
        min-height: auto;
        margin-bottom: 0;
      }
    }

    /deep/ .multiselect__select {
      position: relative;
      display: inline-flex;
      height: auto;
      width: auto;
      margin-left: 10px;
      padding: 0;

      &:before {
        top: 12%;
      }
    }
    
    /deep/ .multiselect__content-wrapper {
      margin-top: 28px;
    }

    &.multiselect--active {
      /deep/ .multiselect__select:before {
        top: 50%;
      }
    }
  }
}

.stick-btn-holder--open-ticket {
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  margin-bottom: -1.125rem;
  margin-left: -2.25rem;
  margin-right: -2.25rem;
}
</style>
